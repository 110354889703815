import styled from "styled-components";
import media from "@styles/media";
import { TemplateViewFeaturesWrapper } from "@svg/TemplateViewFeatures";

const Features = styled.div`
  min-height: 600px;
  z-index: 100;
  position: relative;
  margin-top: 100px;
  ${media.toTabletP`
    background: transparent
            linear-gradient(to right, #13b0e5 0%, #22259b 100%) 0% 0%
            no-repeat padding-box;
    padding: 40px 0;
  `}
  ${TemplateViewFeaturesWrapper} {
    position: absolute;
    z-index: 100;
    ${media.toTabletP`
      display: none;
    `}
  }
`;

export default Features;
